.resume {
  max-width: 768px;
  margin: auto;
  padding: 20px;
}

.resume a {
  text-decoration: none;
}

.resume-header {
  text-align: center;
  margin-bottom: 40px;
}

.logo {
  height: 0.78em;
  margin-right: 1px;
}

.header-key:not(:last-child)::after {
  display: block;
  content: "\a";
}

.resume-summary,
.resume-skills,
.resume-timeline {
  position: relative;
  margin-bottom: 40px;
  margin-left: 20px;
}

.resume-timeline > :not(h2) {
  margin-left: 5px;
}

.resume-summary :not(h2),
.resume-skills :not(h2) {
  animation: fadeIn 1s ease-out;
}

.resume-skills ul {
  list-style: none;
  padding: 0;
}

.resume-skills li {
  display: inline-block;
  padding: 2px 4px;
  margin-right: 6px;
  margin-top: 6px;
  border: 1px solid cyan;
  border-radius: 5px;
}

.timeline-block {
  position: relative;
}

.timeline-date {
  padding-left: 20px;
  animation: fadeIn 1s ease-out;
}

.timeline-date::before {
  content: "";
  position: absolute;
  left: -3px;
  top: 6px;
  rotate: 45deg;
  width: 4px;
  height: 4px;
  border: 2px solid #333;
  background-color: #ffffff;
}

.resume-education .timeline-date::before {
  left: -3px;
  top: 6px;
  rotate: 45deg;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.timeline-content {
  border-left: 1px solid #333;
  padding-left: 20px;
  animation: fadeAndSlideIn 1s ease-out;
}

.timeline-pointer {
  display: list-item;
  margin-left: 15px;
}

.certificate-link:not(:only-child)::before {
  content: ".";
  margin: 0 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeAndSlideIn {
  from {
    padding-left: 0px;
    opacity: 0;
  }
  to {
    padding-left: 20px;
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .resume-header {
    text-align: left;
    margin-left: 20px;
  }

  .header-key:not(:last-child)::after {
    display: inline;
    content: " | ";
  }

  .resume > hr {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .resume {
    padding: 10px;
  }
}
